


















































































import { Mixin_list } from "@/mixin";
import { api_role, role } from "@/http/role";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";

@Component({
  components: {
    Page,
    Table,
  },
})
export default class extends Mixin_list<role>(api_role.get_list) {
  filter = {
    name: "",
  };

  columns = [
    {
      label: "角色名称",
      prop: "name",
    },
    {
      label: "岗位描述",
      prop: "desc",
    },
    // {
    //   label: "状态",
    //   prop: "desc",
    // },
    {
      label: "添加时间",
      prop: "create_time",
    },
    {
      label: "操作",
      prop: "",
      slot: "operation",
    },
  ];

  click_cell(row: role) {
    return this.$router.push(`/auth/role/edit/?id=${row.id}`);
  }

  async del(id: number) {
    await api_role.del_one(id);
    this.get_list();
  }
  reset(){
    this.filter.name = ''
    this.get_list()
  }
}
